import { getToken } from 'firebase/messaging';
import { useCallback, useEffect } from 'react';
import { auth, getFirebaseMessaging } from 'services/firebase';
import { logError } from 'services/logging';
import { isLocal, isProd } from 'config/env';
import { isNotificationsEnabled } from 'config/features';
import { ROBOT_TOKEN_EMAIL } from 'hooks/useUser';
import { useRegisterDeviceMutation } from 'generated/graphql';

const STORAGE_KEY = 'deviceToken';

const useSyncPushToken = () => {
  const user = auth.currentUser;
  const [, executeMutation] = useRegisterDeviceMutation();

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

  const handleSyncIOS = useCallback(async () => {
    const token = localStorage.getItem(STORAGE_KEY);
    if (!token) {
      return;
    }
    await executeMutation({
      input: {
        token,
      },
    });
  }, [executeMutation]);

  const handleSyncNew = useCallback(async () => {
    if (!isLocal) {
      const messaging = await getFirebaseMessaging();

      if (!messaging || Notification.permission === 'denied') {
        localStorage.removeItem(STORAGE_KEY);
        return;
      }
      try {
        const registration = await navigator.serviceWorker.ready;
        const storageToken = localStorage.getItem(STORAGE_KEY);
        const token = await getToken(messaging, {
          vapidKey: import.meta.env.REACT_APP_FIREBASE_VAPID_TOKEN,
          serviceWorkerRegistration: registration,
        });

        if (storageToken !== token) {
          localStorage.setItem(STORAGE_KEY, token);
          await executeMutation({
            input: {
              token,
            },
          });
        }
      } catch (error: any) {
        localStorage.removeItem(STORAGE_KEY);
      }
    }
  }, [executeMutation]);

  const handleSyncOld = useCallback(async () => {
    if (!isLocal) {
      const messaging = await getFirebaseMessaging();
      if (!messaging) {
        logError(new Error(`Messaging is not supported (${user?.email ?? 'unknown'})`));
      } else {
        try {
          let token;
          const storageToken = window.localStorage.getItem(STORAGE_KEY);
          if (storageToken) {
            token = storageToken;
          } else {
            const registration = await navigator.serviceWorker.ready;
            token = await getToken(messaging, {
              vapidKey: import.meta.env.REACT_APP_FIREBASE_VAPID_TOKEN,
              serviceWorkerRegistration: registration,
            });
          }
          await executeMutation({
            input: {
              token,
            },
          });
        } catch (error: any) {
          logError(new Error(`Device token registration failed (${user?.email ?? 'unknown'}). ${error?.message}`));
        }
      }
    }
  }, [executeMutation, user?.email]);

  const handleSync = useCallback(() => {
    if (!isProd) console.log('isIOS', isIOS);
    if (isIOS) {
      handleSyncIOS();
      return;
    }
    if (isNotificationsEnabled) {
      handleSyncNew();
    } else {
      handleSyncOld();
    }
  }, [handleSyncNew, handleSyncOld, handleSyncIOS, isIOS]);

  const handleShouldSync = useCallback(() => {
    const isRobotUser = user?.email === ROBOT_TOKEN_EMAIL;
    if (user?.email && !isRobotUser) {
      handleSync();
    }
  }, [user?.email, handleSync]);

  //If a user closes the app, goes into settings, enables notifications and returns it should prompt again
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!isNotificationsEnabled) return;

      if (document.visibilityState === 'visible' && Notification.permission !== 'denied') {
        handleShouldSync();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [handleShouldSync]);

  useEffect(() => {
    const handleStorageChange = (event: MessageEvent) => {
      if (event.data && event.data.type === 'storageChange') {
        console.log('Storage change detected from Swift:', event.data);
        handleSyncIOS();
      }
    };

    window.addEventListener('message', handleStorageChange);

    return () => {
      window.removeEventListener('message', handleStorageChange);
    };
  }, [handleSyncIOS]);

  //If a change in token in storage occurs, sync it. Only for ios.
  useEffect(() => {
    const handleStorageChange = async (event: StorageEvent) => {
      if (!isNotificationsEnabled) return;

      if (!isProd) console.log(`storage change ${JSON.stringify(event)}, ${event.key}, ${STORAGE_KEY}`);
      if (event.key === STORAGE_KEY) {
        handleSyncIOS();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [handleSyncIOS]);

  useEffect(() => {
    handleShouldSync();
  }, [handleShouldSync]);

  return handleSync;
};

export default useSyncPushToken;
